import React from "react"

// Components
import { Link, graphql } from "gatsby"
import "./category-tem.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap'
import Iconsbar from "../components/iconsbar/navigationLine";

import Layout from "../components/layout";
import SnackBar from "../components/snackbar/snackbar";
import Img from "gatsby-image"

const Tags = ({ pageContext, data, title }) => {
	const { tag } = pageContext
	const { totalCount } = data.allMarkdownRemark
	const tagHeader = `${totalCount} Song${totalCount === 1 ? "" : "s"
		} in "${tag}"`

	return (
		<div>
			<Layout />
			<Iconsbar />
			<Container fluid className="bgCategory px-0">
				<Container>
					<div className="categoryCont">
						<Row className="description">
							<Col className="py-5 text-center text-white">
								<h1>{tagHeader}</h1>
							</Col>
						</Row>
						<Row className="categoryList">
							<Col >
								{data.allMarkdownRemark.edges.map(({ node }) => (
									<ul type="none">
										<li>
											<Row>
												<Col xs={4} md={4} className="align-self-center pl-0">
													<Img fluid={node.frontmatter.featureImage.childImageSharp.fluid} />
												</Col>
												<Col xs={8} md={8} className="align-self-center">
													<Link to={node.fields.slug} className="cat_song_title">{node.frontmatter.title}</Link>
													<p><Link to={node.fields.slug} className="cat_song_artist text-muted">{node.frontmatter.artist}</Link></p>
												</Col>
											</Row>
											
										</li>
									</ul>
								))}
							</Col>
						</Row>
						<Row>
							<Col className="text-right pt-4">
								<p><Link to="/category">All categories</Link></p>
							</Col>
						</Row>
					</div>
				</Container>
			</Container>
		</div>
	)
}


export default Tags

export const pageQuery = graphql`
  query Category($tag: [String]){
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/sheets/"}, 
    							frontmatter: {tags: {in: $tag}}},
								sort: { fields: [frontmatter___title], order: ASC }) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            artist
            key
			featureImage 
						{
							childImageSharp 
							{
								fluid(maxWidth: 500, quality: 100) 
								{
									...GatsbyImageSharpFluid
									...GatsbyImageSharpFluidLimitPresentationSize
								}
							}
						}
          }
        }
      }
    }
    site{
      siteMetadata{
        title
      }
    }
  }
`